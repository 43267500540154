import React from "react";
import Layout from "../components/layout";

import "react-tabs/style/react-tabs.css";

const IndexPage = () => (
  <Layout>
    <hr />
    <div
      style={{
        color: "black",
        paddingLeft: "4rem",
        paddingRight: "4rem",
      }}
    >
      <h1 style={{ textAlign: "center", fontWeight: "bold", fontSize: "2rem" }}>
        Terms of service
      </h1>

      <p>
        By using Inflowkit Services (as defined below) you are agreeing to be
        bound by the following terms and conditions (“Terms of Service”). You
        must read, agree to and accept all of the terms and conditions contained
        in the Terms of Service and Privacy Policy (and any applicable data
        protection addendum).
      </p>
      <p>
        This Terms of Service, Privacy Policy and any applicable DPA
        collectively forms your agreement (the “Agreement”) and governs your
        access to, and use of, the websites, services, applications and
        integrations (collectively, the “Services”) which are owned, operated or
        provided by or on behalf of Tembo Labs Ltd.
      </p>
      <p>
        As used in this Agreement, “you” and “your” refers to you, the person
        accessing the Services; “we” means (and “us”, “our”, “ours” and
        “ourselves” refer to) Tembo Labs Ltd; and “party” or “parties” refers to
        both you and us. The effective date of this Agreement is when you accept
        it, in accordance with the terms and conditions that are set out below.
      </p>

      <p>
        IMPORTANT! YOUR ACCESS TO AND USE OF THE SERVICES IS SUBJECT TO LEGALLY
        BINDING TERMS AND CONDITIONS. PLEASE CAREFULLY READ ALL OF THE FOLLOWING
        TERMS AND CONDITIONS BEFORE YOU PROCEED. ACCESSING OR USING THE SERVICES
        IS THE EQUIVALENT OF YOUR SIGNATURE AND INDICATES YOUR ACCEPTANCE OF
        THESE TERMS AND CONDITIONS AND THAT YOU INTEND TO BE LEGALLY BOUND BY
        THEM. IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, PLEASE DO NOT
        USE THE SERVICES.
      </p>

      <p>
        You can view, print, download or save this Agreement at any time, under
        the “Terms of Service” link that appears on our website. We reserve the
        right to change these terms and conditions at any time without notice,
        by updating this Agreement, and such changes will be effective as of the
        date these updates (or an updated version of this Agreement) is posted
        to our website. We may provide notice to you of material revisions by
        means of a general notice on our website. Your continued use of the
        Services after such revisions are posted will signify your agreement to
        these revised terms. Therefore, you should visit this page periodically
        to review this Agreement.{" "}
      </p>
      <h2>Our services</h2>
      <p>
        Our app inflowkit enables you to offer courses on your online store.
        This app is designed to add value on feature to your existing online
        store.
      </p>

      <p>
        We take reasonable efforts to explain each Service’s features to you on
        our website, but we can’t guarantee a Service will look or perform
        exactly like it appears on a demo page. If you have any questions about
        any terms or details of any of our Services, please reach out to through
        our contact email.
      </p>
      <p>
        Tembo Labs Limited shall use commercially reasonable efforts to provide
        technical support for Services. We are not responsible for providing
        technical support for any apps, products, or services provided to you by
        third parties.
      </p>
      <h2>Payments and Terms</h2>
      <p>
        All payments to Tembo Labs Limited do not include applicable sales,
        goods and services, harmonized, or any other taxes or fees, which may be
        charged by governmental authorities. Such taxes and/or fees will be
        added to the total amount you are required to pay and billed to your
        method of payment. Payment of these taxes and/or fees will be your sole
        responsibility
      </p>
      <p>
        You agree to indemnify and hold harmless Tembo Labs Limited and its
        officers, directors, subsidiaries, affiliates, employees,
        representatives, agents, licensors and their respective successors and
        assigns (collectively, its “Others”), against any chargeback costs (plus
        any taxes or related fees) which we are required to pay, as a result of
        a payment dispute with you or in relation to a payment you have made to
        us for the Services.
      </p>

      <p>
        In the event that you are billed directly by us, in most cases, you will
        be billed in advance of your payment period, typically monthly. In some
        cases, such as for overages, you may be billed in the month after the
        overages incurred. You must make all payments, without any setoff or
        deduction for any reason, within fifteen (15) days of the date of your
        invoice in a manner determined by us in our sole discretion.
      </p>

      <p>All payments are made payable in USD (United States Dollars).</p>

      <h2>Trial Period</h2>
      <p>
        We may offer a trial period for certain Services before being required
        to purchase or subscribe. The duration and specific terms of the trial
        period will be published to our websites and during the Services install
        process. You will not be charged for the Services until the trial period
        has expired. You agree that even though you may have access to the
        Services on a trial basis, free of charge, you will be bound by the
        terms and conditions of this Agreement. Your continued use of the
        Services following the conclusion of the trial period constitutes your
        consent to be charged for those Services and to the provisions in this
        Agreement.
      </p>
      <h2>Right to use the Services and Intellectual Property</h2>
      <p>
        Subject to your compliance with the terms and conditions of this
        Agreement, we hereby grant to you a limited, non-exclusive,
        non-assignable, non-sublicensable, revocable right to install and use
        the Services for the purpose of operating your online store. This right
        terminates upon termination of this Agreement or any other agreements
        previously provided to you by us, as may be applicable. For certainty,
        if you uninstall inflowkit app , your Agreement is terminated
        automatically. Any and all rights not expressly granted to you are
        reserved by us, and this Agreement does not confer to you a proprietary
        interest in any Services.
      </p>

      <p>
        Certain words, phrases, names, designs or logos made available on or
        through the Services may constitute trademarks, service marks, or trade
        names that are owned by us or others. The display of such marks on or
        through the Services does not imply that you have been granted a licence
        by us or others with respect to them.
      </p>

      <p>
        If you believe in good faith that any material that is made available on
        or through the Services, infringes your copyright, please notify us
        using our contact email.
      </p>

      <h2>Termination</h2>
      <p>
        Subject to issuing a two (2) week notice, Tembo Labs Limited may
        terminate User access to all or any part of the Website or Service at
        any time, with or without cause, with or without notice, effective
        immediately. If User wishes to terminate its Agreement , User may
        discontinue using the Website. Notwithstanding the foregoing, paid
        accounts can only be terminated by Tembo Labs Limited where there has
        been a material breach of the Agreement with Tembo Labs Limited and such
        breach has not been cured within thirty (30) days of notice by Tembo
        Labs Limited of such breach; provided that, Tembo Labs Limited reserves
        the right to terminate the Website immediately as part of a general shut
        down of the services offered. All provisions of such Agreement and these
        terms, which by their nature should survive termination, shall survive
        termination, including, without limitation, ownership provisions,
        indemnity and limitations of liability.
      </p>

      <h2>Third Party Services and Content</h2>
      <p>
        We are not a party to any relationship between you and any third party,
        including, but not limited to, you and your eCommerce platform or you
        and your customers (your “Customers”), and as such, we have no
        responsibility to you as regards to your relationships with these third
        parties. You acknowledge and agree that you have no recourse against us
        for any acts or omissions of third parties, and your interaction with
        third parties is entirely at your own risk.
      </p>
      <p>
        Your use of the Services may rely on services and products which are
        offered by third parties (“Third Party Services”). We have no
        responsibility to you for anything third parties do (or fail to do) and
        we provide no warranties or guarantees about third parties or Third
        Party Services. Your use of Third Party Services may be subject to
        specific terms and conditions which are set by those third parties.
      </p>
      <p>
        We may make third parties’ content and materials (“Third Party Content”)
        available to you through our websites, such as reviews. Our making
        available of such Third Party Content does not constitute an endorsement
        or recommendation, and we are not responsible for any reliance you may
        place on Third Party Content. We make no warranties or representations
        as to any Third Party Content and we shall have no liability for it. Any
        opinions or statements made by third parties are those of such third
        parties, and do not necessarily state or reflect our views.
      </p>
      <p>
        You agree that we will have no liability to you with respect to the
        acts, omissions, errors, representations, warranties, breaches or
        negligence for any damages or expenses resulting in any manner from your
        interactions with any: a) third parties; b) Third Party Services; or c)
        Third Party Content, and we are not obliged to become involved in any
        disputes you may have with any third parties. If you have a dispute with
        any third parties, you release Tembo Labs Limited and its Others from
        any direct, indirect, incidental, special, consequential, exemplary or
        other damages whatsoever, including, without limitation, any direct,
        indirect, incidental, special, consequential, exemplary or other
        damages, arising out of or in any way related to such disputes and/or
        our Services.
      </p>
      <p>
        User agrees that all content and materials (collectively, "Content")
        delivered via the Service or otherwise made available by Tembo Labs
        Limited at the Website are protected by copyrights, trademarks, service
        marks, patents, trade secrets or other proprietary rights and laws.
        Tembo Labs does not support the distribution of pornographic content.
        Except as expressly authorized by Tembo Labs Limited in writing, User
        agrees not to sell, license, rent, modify, distribute, copy, reproduce,
        transmit, publicly display, publicly perform, publish, adapt, edit or
        create derivative works from any legally prohibited materials or
        content.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        Some of our Services may permit you to sell or otherwise enable Uploaded
        Content, which is material that has been uploaded by Content Uploaders.
        When you sell or otherwise enable Uploaded Content from Content
        Uploaders, the following rules apply (in addition to all other terms of
        this Agreement). You grant us a non-exclusive, worldwide, royalty-free,
        irrevocable, sub-licensable, perpetual licence to use, display, edit,
        modify, reproduce, distribute, store, and prepare derivative works of
        Uploaded Content, to provide the Services and to promote the Services,
        in any formats and through any channels, whether now or later existing
        (and you represent and warrant that you have obtained the necessary
        permissions and rights to grant us this licence). You acknowledge that
        WE HAVE NO LEGAL RELATIONSHIP WITH CONTENT UPLOADERS, and we are not
        responsible for the Uploaded Content, or anything else related to
        Content Uploaders. UPLOADED CONTENT AND CONTENT UPLOADERS ARE SOLELY
        YOUR RESPONSIBILITY. YOU WILL BE RESPONSIBLE FOR ANY LOSSES OR DAMAGES
        WE SUFFER BECAUSE OF UPLOADED CONTENT OR CONTENT UPLOADERS. Without
        limiting any other limitation of liability, indemnity or release set out
        in this Agreement, you agree that we have no liability to you for any
        losses or damages you might suffer because of Uploaded Content or
        Content Uploaders. Further, you agree to indemnify and hold harmless
        Tembo Labs Limited and its Others from any claim or demand (including
        reasonable legal fees, expert fees and other reasonable litigation
        costs), arising from, incurred as a result of, or in any manner related
        to, Uploaded Content or Content Uploaders. You represent and warrant
        that Content Uploaders have the necessary rights (including, without
        limitation, intellectual property rights) to: a) upload Uploaded
        Content; and b) enable you to use (including offering for purchase)
        Uploaded Content, as part of your use of the Services. We do not review
        Uploaded Content and you are solely responsible for ensuring it complies
        with this Agreement and all applicable laws.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        To the maximum extent permitted by law, under no circumstances shall you
        be entitled to recover any special, incidental, consequential or
        indirect damages from Tembo Labs Limited or its Others, which you may
        suffer arising out of, caused by, or in connection with, the use, or
        inability to use, the Services, any inaccuracy, incompleteness or
        incorrectness contained in the materials displayed, accessed or used as
        part of the Services, or your reliance or acting upon the materials used
        as part of the Services, including any loss or damages in the nature of
        or relating to lost business, lost savings, lost data and/or profits,
        regardless of the cause and whether arising in contract, tort, in
        equity, at law or otherwise, and whether or not Tembo Labs Limited has
        or had been advised of the possibility of such losses or damages. Unless
        otherwise agreed to by you and Tembo Labs Limited in writing, under no
        circumstances shall the liability of Tembo Labs Limited and its Others
        to you, for damages or losses suffered by you arising out of, related to
        or caused by, the Services, or the use thereof, exceed a maximum equal
        to the amount actually paid by you for the Services in the three (3)
        months’ period preceding such losses or damages. You acknowledge and
        agree that the payments paid by you for the Services reflect the
        allocation of risk set forth in this Agreement and that Tembo Labs
        Limited would not enter into this Agreement without these limitations on
        liability.
      </p>
      <p>
        You agree to release, remise and acquit Tembo Labs Limited and its
        Others from any claims, actions, demands, costs and expenses of any kind
        whatsoever, whether in contract, negligence or tort, at law or in
        equity, or by statute or otherwise, howsoever caused, with respect to
        your use of, or our operation of, the Services, except as may be set out
        in the preceding paragraph.
      </p>
      <h2>Indemnity</h2>
      <p>
        You agree to indemnify and hold harmless Tembo Labs Limited and its
        Others from any claim or demand (including reasonable legal fees, expert
        fees and other reasonable litigation costs), arising from, incurred as a
        result of, or in any manner related to, your use of the Services,
        including, but not limited to: a) your breach of this Agreement; b) any
        misrepresentation made by you to any third party; c) any third party
        claim in respect of the Services involving or related to your or your
        Customers’ use of the Services; d) your ability or your Customers’
        ability to access and use the Services; or e) your violation of
        applicable laws, rules or regulations or the rights of any third party.
      </p>

      <h2>Copyright</h2>
      <p>
        All content included on the Website, such as text, graphics, logos,
        button icons, images, audio clips, digital downloads, data compilations,
        and software, is the property of Tembo Labs Limited or its content
        suppliers and protected by international copyright laws. The compilation
        of all content on the Website is the exclusive property of Tembo Labs
        Limited and protected by international copyright laws. All software used
        on (or provided through) the Website is the property of Tembo Labs
        Limited or its software suppliers and protected by international
        copyright laws.
      </p>
      <h2>General Provisions</h2>
      <p>
        These terms and any action related thereto will be governed by and
        construed in accordance with the substantive laws of the Republic of
        Kenya, without regard to conflicts of law principles. The Parties will
        initiate any lawsuits or dispute resolution mechanisms in connection
        with these terms in London, England and irrevocably submit to the
        exclusive personal jurisdiction and venue of the courts sitting therein.
        The U.N. Convention on Contracts for the International Sale of Goods
        will not apply to these terms.
      </p>
    </div>
  </Layout>
);

export default IndexPage;
